import debug from 'debug';
import { util } from './shared';

const mydebug = debug("mywmfg");

//"mywmfg:* -mywmfg:*-trace" show all except trace
//"-mywmfg:(files|subscr)-trace" shows traces except for files and subscr namespaces
let defaultFilter = util.isdevsystem()
	? 'mywmfg:* -mywmfg:(lang|sess|recursiveMerge)-trace'
	: 'mywmfg:* -mywmfg:*-trace';
debug.enable(defaultFilter);

const COLORS = {
	trace: "lightblue",
	info: "green",
	warn: "yellow", 
	error: "red"
};

const logger = {};
const msghist = [];

const keepmsgs = 8;

export const log = {
	//this is a full override of the log filter, and affects console as well as what gets sent to the log server
	setFilter: (filter) => {
		if(!filter) {
			debug.enable(defaultFilter);
			log.warn("_", "Log Level now default %s", defaultFilter);
		} else {
			debug.enable(filter);
			log.warn("_", "Log Level now %s", filter);
		}
	},
	generateMessage: async (level, namespace, args) => {
		if(!logger[`${namespace}-${level}`]) {
			logger[`${namespace}-${level}`] = mydebug.extend(`${namespace}-${level}`);
		}
		//doesn't try if not enabled, more efficient
		if(logger[`${namespace}-${level}`].enabled) {
			// Set the colour of the message based on the level
			logger[`${namespace}-${level}`].color = COLORS[level];

			logger[`${namespace}-${level}`](...args);
			if(log.onLog) log.onLog({level, namespace, args});
		}
		//enabled or not, keep the last few messages
		msghist.push({level, namespace, args});
		while(msghist.length > keepmsgs) {
			msghist.shift();
		}
	},

	getHist: () => {
		return msghist;
	},

	trace: (namespace, ...args) => {
		log.generateMessage('trace', namespace, args);
	},

	info: (namespace, ...args) => {
		log.generateMessage('info', namespace, args);
	},

	warn: (namespace, ...args) => {
		log.generateMessage('warn', namespace, args);
	},

	error: (namespace, ...args) => {
		log.generateMessage('error', namespace, args);
	},
}