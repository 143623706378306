import React, { useContext, useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import {
	filterOperators,
	CheckBox,
	ComboBox,
	DateBox,
	DataList,
	Form,
	FormField,
	LinkButton,
	InputBase,
	PasswordBox,
	TextBox,
	Tooltip,
	RadioButton,
	Label,
	DataGrid,
	GridColumn
} from 'rc-easyui';
import { SessionContext } from '../data/Session';
import { util } from '../data/shared';

export const filterExtras = Object.assign(filterOperators, {
	daymatch: {
		text: 'DateContains',
		isMatch: function (source, value) {
			source = String(source);
			value = String(value).toLowerCase();
			return (moment(source).format("YYYY/MM/DD").toLowerCase().includes(value) === true);
		}
	},
});

export const Fm = ({ fmRef, children, style, ...rest }) => {
	let v = Validators();
	rest = {
		...{
			errorType: "tooltip",
			labelAlign: "right"
		}, ...rest
	};

	return (
		<Form
			ref={fmRef}
			style={{ ...{ maxWidth: 500 }, ...style }}
			validateRules={v}
			{...rest}
		>
			{children}
		</Form>
	);
}

export const Button = ({ title, children, ...rest }) => {
	return (
		<Tooltip tooltipStyle={{ maxWidth: "300px" }} content={title}><LinkButton {...rest}>{children}</LinkButton></Tooltip>
	);
}

export const ButtonField = ({ className, label, title, hidden, invisible, onClick, style, btnStyle, ...rest }) => {
	if (hidden === true) {
		if (!style) style = {};
		style["display"] = "none";
	}
	if (invisible === true) {
		if (!style) style = {};
		style["visibility"] = "hidden";
	}
	return (
		<FormField className={className} style={{ ...{ marginBottom: "5px", marginRight: "10px" }, ...style }}>
			<Tooltip tooltipStyle={{ maxWidth: "300px" }} content={title}><LinkButton onClick={onClick} className="f-full" style={btnStyle} {...rest} >{label}</LinkButton></Tooltip>
		</FormField>
	);
}

export const CheckField = ({ label, name, hidden, invisible, labelPosition, labelAlign, labelWidth, style, addonRight, tooltip, txtStyle, editable, disabled, ...rest }) => {
	if (hidden === true) {
		if (!style) style = {};
		style["display"] = "none";
	}
	if (invisible === true) {
		if (!style) style = {};
		style["visibility"] = "hidden";
	}
	if (addonRight) {
		if (!rest.style) rest.style = {};
		rest.style.float = "left";
	}

	return (
		<FormField name={name} label={label} labelPosition={labelPosition} labelAlign={labelAlign} labelWidth={labelWidth === "form" ? null : (labelWidth || "unset")} style={{ ...{ marginBottom: "5px", marginRight: "10px" }, ...style }}>
			<Tooltip tooltipCls="form-field-focused" tooltipStyle={{ maxWidth: "300px" }} position="top" content={tooltip}><i className={tooltip ? "fas fa-question-circle" : ""} style={{ fontSize: "14px" }} /></Tooltip>
			<CheckBox {...rest} style={txtStyle} disabled={disabled || (editable === undefined ? false : !editable)} />
			<span>{addonRight ? addonRight() : ""}</span>
		</FormField>
	);
}

export class CheckGrid extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			clicked: false,
			data: this.props.data,
			selectedRows: [], //Array.isArray(this.props.selection)? this.props.selection : []
		}
	}
/* 	componentDidMount() {
		console.log("CheckGrid mount", {props: this.props.selection, state:this.state.selectedRows})
		if(Array.isArray(this.props.selection)) {
			this.setState({ selectedRows: this.props.selection })
		} else {
			this.setState({ selectedRows: [] })
		}
	}
 */	componentDidUpdate(prevProps) {
		//console.log("CheckGrid update", {props: this.props.selection, prev:prevProps.selection, state:this.state.selectedRows})
		if (this.props.selection !== prevProps.selection) {
			this.setState({ selectedRows: this.props.selection })
		}
		if (this.props.data !== prevProps.data) {
			this.setState({ data: this.props.data })
		}
	}

	isChecked(row) {
		if (this.props.idField) {
			const index = this.state.selectedRows.findIndex(s => s[this.props.idField] === row[this.props.idField]);
			if (index >= 0) {
				return true;
			}
		} else {
			const index = this.state.selectedRows.indexOf(row);
			if (index >= 0) {
				return true;
			}
		}
		return false;
	}
	isAllChecked() {
		const { selectedRows, data } = this.state;
		if (selectedRows.length && selectedRows.length === data.length) {
			return true;
		}
		return false;
	}
	handleRowCheck(row, checked) {
		//console.log("handleRowCheck", checked,row)
		if (this.state.clicked) {
			return;
		}
		const index = this.state.data.indexOf(row);
		const data = this.state.data.slice();
		let selection = this.state.selectedRows.slice();
		data.splice(index, 1, Object.assign({}, row));
		this.setState({ data: data })
		if (checked) {
			selection = [...selection.filter(r => {
				if (this.props.idField) {
					if (r[this.props.idField] !== row[this.props.idField]) {
						return true;
					}
				} else {
					if (r !== row) {
						return true;
					}
				}
				return false;
			}), data[index]] //filter so it doesn't add a 2nd time
		} else {
			selection = selection.filter(r => {
				if (this.props.idField) {
					if (r[this.props.idField] !== row[this.props.idField]) {
						return true;
					}
				} else {
					if (r !== row) {
						return true;
					}
				}
				return false;
			});
		}
		this.setState({ selectedRows: selection, clicked: true }, () => {
			setTimeout(() => this.setState({ clicked: false }))
			if (this.props.onSelectionChange) {
				this.props.onSelectionChange(this.state.selectedRows)
			}
		})
	}
	handleAllCheck(checked) {
		if (this.state.clicked) {
			return;
		}
		const data = this.state.data.map(row => Object.assign({}, row))
		this.setState({
			data: data,
			selectedRows: checked ? data : [],
			clicked: true
		}, () => {
			setTimeout(() => this.setState({ clicked: false }))
			if (this.props.onSelectionChange) {
				this.props.onSelectionChange(this.state.selectedRows)
			}
		})
	}
	render() {
		return (
			<DataGrid {...this.props} data={this.state.data} ref={el => this.datagrid = el}>
				<GridColumn key="ck_column_key" width={50} align="center"
					field="ck"
					render={({ row }) => (
						<CheckBox checked={this.isChecked(row)} onChange={(checked) => this.handleRowCheck(row, checked)}></CheckBox>
					)}
					header={() => (
						<CheckBox checked={this.isAllChecked()} onChange={(checked) => this.handleAllCheck(checked)}></CheckBox>
					)}
					filter={() => <span></span>}
				/>
				{this.props.children}
			</DataGrid>
		)
	}
}

export const RadioField = ({ label, name, value, data, hidden, invisible, labelPosition, labelAlign, labelWidth, style, tooltip, ...rest }) => {
	if (hidden === true) {
		if (!style) style = {};
		style.display = "none";
	}
	if (invisible === true) {
		if (!style) style = {};
		style.visibility = "hidden";
	}
	let idx = 1;
	return (
		<FormField name={name} label={label} labelPosition={labelPosition} labelAlign={labelAlign} labelWidth={labelWidth === "form" ? null : (labelWidth || "unset")} style={{ ...{ marginBottom: "5px", marginRight: "10px" }, ...style }}>
			<Tooltip tooltipCls="form-field-focused" tooltipStyle={{ maxWidth: "300px" }} position="top" content={tooltip}><i className={tooltip ? "fas fa-question-circle" : ""} style={{ fontSize: "14px" }} /></Tooltip>

			{data.map(d => (<div key={idx++}>
				<RadioButton name={name} inputId={name + d.value} value={d.value} groupValue={value} {...rest} {...d} />
				<Label htmlFor={name + d.value}>{d.text}</Label>
			</div>))}
		</FormField>
	);
}

export const ComboField = ({ cboref, label, name, hidden, invisible, showastext, tooltip, labelPosition, labelAlign, labelWidth, style, cboStyle, panelStyle, onFocusChange, ...rest }) => {
	const { t } = useContext(SessionContext);
	const cboBox = useRef(null);
	const [myState, setMyState] = useState(false);

	if (!style) style = {};
	if (hidden === true) {
		style["display"] = "none";
	}
	if (invisible === true) {
		style["visibility"] = "hidden";
	}

	if (showastext) {
		rest.disabled = false;
		let thisitem = rest.data?.find(r => r[rest.valueField || "value"] === rest.value);
		if (thisitem) {
			rest.value = thisitem[rest.textField || "text"];
		} else if(rest.value){
			thisitem = {};
			thisitem[rest.valueField || "value"] = rest.value;
			thisitem[rest.textField || "text"] = rest.value;
		}
		if (rest.renderItem && thisitem) {
			tooltip = () => { return rest.renderItem({ t, row: thisitem }) }
		}
		return (
			<FormField style={{ ...{ marginBottom: "5px", marginRight: "10px" }, ...style }}
				label={label} labelPosition={labelPosition} labelAlign={labelAlign}
				labelWidth={labelWidth === undefined ? "unset" : labelWidth}
			>
				<Tooltip tooltipCls="form-field-focused" tooltipStyle={{ maxWidth: "300px" }} position="top" content={tooltip}><i className={tooltip ? "fas fa-question-circle" : ""} style={{ fontSize: "14px" }} /></Tooltip>
				<TextBox {...rest} style={cboStyle}
					onFocus={() => { rest.onFocus && rest.onFocus(); onFocusChange && onFocusChange({ name, hasfocus: true }) }}
					onBlur={() => { rest.onBlur && rest.onBlur(); onFocusChange && onFocusChange({ name, hasfocus: false }) }}
				/>
			</FormField>
		);
	} else {
		return (
			<FormField name={name} label={label} labelPosition={labelPosition} labelAlign={labelAlign} labelWidth={labelWidth === "form" ? null : (labelWidth || "unset")}
				style={{ ...{ marginBottom: "5px", marginRight: "10px" }, ...style }}>
				<Tooltip tooltipCls="form-field-focused" tooltipStyle={{ maxWidth: "300px" }} position="top" content={tooltip}><i className={tooltip ? "fas fa-question-circle" : ""} style={{ fontSize: "14px" }} /></Tooltip>
				<ComboBox ref={ref => { cboBox.current = ref; if (cboref) cboref.current = ref; }} {...rest} style={cboStyle} panelStyle={{ height: 'auto', maxHeight: "200px", ...panelStyle }}
					onFocus={() => {
						if (cboBox.current) {
							setTimeout(() => { //pause a little to wait for clicks to register
								if (!rest.editable && !myState) {
									setMyState(true);
									cboBox.current?.openPanel();
								}
							}, 200);
						}
						rest.onFocus && rest.onFocus();
						onFocusChange && onFocusChange({ name, hasfocus: true })
					}}
					onBlur={() => {
						if (cboBox.current) {
							if (!rest.editable && myState) {
								setTimeout(() => {
									setMyState(false);
								}, 500);
							}
						}
						rest.onBlur && rest.onBlur();
						onFocusChange && onFocusChange({ name, hasfocus: false });
					}}
				/>
			</FormField>
		);
	}
}

export const DateField = ({ label, name, hidden, invisible, showastext, tooltip, labelWidth, style, dtStyle, onFocusChange, ...rest }) => {
	const { t } = useContext(SessionContext);
	const dtBox = useRef(null);

	if (!style) style = {};
	if (hidden === true) {
		style["display"] = "none";
	}
	if (invisible === true) {
		style["visibility"] = "hidden";
	}
	if (showastext) {
		if (rest.format === "yyyy/MM/dd") {
			rest.format = "YYYY/MM/DD"
		}
		if (rest.value) {
			if (rest.format == "YYYY/MM/DD") {
				//tz() doesn't have a working format()?  wrap it
				rest.value = moment(moment(rest.value).tz('America/Chicago').toDate()).format(rest.format);
			} else {
				rest.value = moment(rest.value).format(rest.format);
			}
		}
		return (
			<FormField name={name} style={{ ...{ marginBottom: "5px", marginRight: "10px" }, ...style }}
				label={label} labelWidth={labelWidth === undefined ? "unset" : labelWidth}
			>
				<Tooltip tooltipCls="form-field-focused" tooltipStyle={{ maxWidth: "300px" }} position="top" content={tooltip}><i className={tooltip ? "fas fa-question-circle" : ""} style={{ fontSize: "14px" }} /></Tooltip>
				<TextBox {...rest} style={dtStyle}
					onFocus={() => { rest.onFocus && rest.onFocus(); onFocusChange && onFocusChange({ name, hasfocus: true }) }}
					onBlur={() => { rest.onBlur && rest.onBlur(); onFocusChange && onFocusChange({ name, hasfocus: false }) }}
				/>
			</FormField>
		);
	} else {
		return (
			<FormField name={name} label={label} labelWidth={labelWidth === "form" ? null : (labelWidth || "unset")} style={{ ...{ marginBottom: "5px", marginRight: "10px" }, ...style }}>
				<Tooltip tooltipCls="form-field-focused" tooltipStyle={{ maxWidth: "300px" }} position="top" content={tooltip}><i className={tooltip ? "fas fa-question-circle" : ""} style={{ fontSize: "14px" }} /></Tooltip>
				<DateBox ref={ref => { dtBox.current = ref; } }{...rest} style={dtStyle} panelStyle={{ width: 250, height: 300 }}
					currentText={t("lbl_today")}
					closeText={t("lbl_close")}
					okText={t("ok")}
					onFocus={() => { rest.onFocus && rest.onFocus(); onFocusChange && onFocusChange({ name, hasfocus: true }) }}
					onBlur={() => { rest.onBlur && rest.onBlur(); onFocusChange && onFocusChange({ name, hasfocus: false }) }}
				/>
			</FormField>
		);
	}
}

export const NumberField = ({ label, name, hidden, invisible, labelPosition, labelAlign, labelWidth, style, tooltip, txtStyle, inputStyle, onFocusChange, ...rest }) => {

	if (hidden === true) {
		if (!style) style = {};
		style["display"] = "none";
	}
	if (invisible === true) {
		if (!style) style = {};
		style["visibility"] = "hidden";
	}

	return (
		<FormField name={name} style={{ ...{ marginBottom: "5px", marginRight: "10px" }, ...style }}
			label={label} labelPosition={labelPosition} labelAlign={labelAlign}
			labelWidth={labelWidth === "form" ? null : (labelWidth || "unset")}
		>
			<Tooltip tooltipCls="form-field-focused" tooltipStyle={{ maxWidth: "300px" }} position="top" content={tooltip}><i className={tooltip ? "fas fa-question-circle" : ""} style={{ fontSize: "14px" }} /></Tooltip>
			<MeasureBox style={txtStyle} {...rest} inputStyle={{ textAlign: "right", ...inputStyle }}
				onFocus={() => { rest.onFocus && rest.onFocus(); onFocusChange && onFocusChange({ name, hasfocus: true }) }}
				onBlur={() => { rest.onBlur && rest.onBlur(); onFocusChange && onFocusChange({ name, hasfocus: false }) }}
			/>
		</FormField>
	);
}

export const TextField = ({ txtRef, label, name, hidden, invisible, labelPosition, labelAlign, labelWidth, spinner, addonRight, style, tooltip, txtStyle, onFocusChange, ...rest }) => {
	const refBox = useRef();
	let spinadd;

	function doSpinner(val) {
		//refBox.current.context.fieldFocus(refBox.current);
		let spinOpts = { ...{ min: 0, max: 1000, increment: 1 }, ...spinner };
		let boxVal = parseInt(refBox.current?.inputRef?.value);
		if (val < 0) {
			if ((boxVal + (val * spinOpts.increment)) >= spinOpts.min) {
				boxVal += (val * spinOpts.increment);
			} else {
				boxVal = spinOpts.min;
			}
		} else {
			if ((boxVal + (val * spinOpts.increment)) <= spinOpts.max) {
				boxVal += (val * spinOpts.increment);
			} else {
				boxVal = spinOpts.max;
			}
		}
		//tell component to update itself with the new value
		refBox.current.setValue(boxVal);
	}

	if (hidden === true) {
		if (!style) style = {};
		style["display"] = "none";
	}
	if (invisible === true) {
		if (!style) style = {};
		style["visibility"] = "hidden";
	}
	if (spinner) {
		spinadd = Object.assign(() => (<TextSpinner disabled={rest.disabled} spin={doSpinner} />), { displayName: "TxtSpinner" });
	}
	return (
		<FormField name={name} style={{ ...{ marginBottom: "5px", marginRight: "10px" }, ...style }}
			label={label} labelPosition={labelPosition} labelAlign={labelAlign}
			labelWidth={labelWidth === undefined ? "unset" : labelWidth}
		>
			<Tooltip tooltipCls="form-field-focused" tooltipStyle={{ maxWidth: "300px" }} position="top" content={tooltip}><i className={tooltip ? "fas fa-question-circle" : ""} style={{ fontSize: "14px" }} /></Tooltip>
			<TextBox ref={ref => {
				refBox.current = ref;
				if (txtRef) txtRef.current = ref;
			}} {...rest} style={txtStyle}
				addonRight={addonRight || spinadd}
				onFocus={() => { rest.onFocus && rest.onFocus(); onFocusChange && onFocusChange({ name, hasfocus: true }) }}
				onBlur={() => { rest.onBlur && rest.onBlur(); onFocusChange && onFocusChange({ name, hasfocus: false }) }}
			/>
		</FormField>
	);
}

const TextSpinner = ({ disabled, spin }) => {
	let style = { fontSize: "6px" };

	return (<div style={style}>
		<Button tabIndex={-1} style={{ height: "18px", width: "15px" }} plain disabled={disabled} onClick={() => spin(1)}><i className="fas fa-caret-up" /></Button><br />
		<Button tabIndex={-1} style={{ top: "-4px", height: "18px", width: "15px" }} plain disabled={disabled} onClick={() => spin(-1)}><i className="fas fa-caret-down" /></Button>
	</div>);

}

export const PasswordField = ({ label, name, labelWidth, style, txtStyle, onFocusChange, ...rest }) => {
	return (
		<FormField name={name} label={label} labelWidth={labelWidth === "form" ? null : (labelWidth || "unset")} style={{ ...{ marginBottom: "5px", marginRight: "10px" }, ...style }}>
			<PasswordBox {...rest} style={txtStyle}
				onFocus={() => { rest.onFocus && rest.onFocus(); onFocusChange && onFocusChange({ name, hasfocus: true }) }}
				onBlur={() => { rest.onBlur && rest.onBlur(); onFocusChange && onFocusChange({ name, hasfocus: false }) }}
			/>
		</FormField>
	);
}

export const ListVertical = ({ loading, data, hidden, style, labelStyle, dataStyle, ...props }) => {
	function renderItem({ row }) {
		return (
			<div className="m-list" style={{ display: 'flex', alignItems: 'baseline' }}>
				<span className="m-list-group" style={{ width: 140, height: "100%", textAlign: 'right', color: "#666", ...labelStyle }}>
					{row.title + ":"}
				</span>
				<span style={{ flex: "auto", ...dataStyle }}>
					{Edit(row)}
				</span>
			</div>
		);
	}
	function Edit(row) {
		if (typeof (row.editor) === "function") {
			return row.editor(row);
		}
		if (typeof (row.editor) === "string") {
			row.editor = {
				type: row.editor,
				postLabel: "",
				txtStyle: {}
			}
		}
		if (row.editor && row.editor.type === "TextField") {
			return (<span>
				<span style={{ display: (row.editor.hidden ? "unset" : "none") }}>{row.value}</span>
				<TextField name={row.name} value={row.value} {...row.editor}
					style={{ display: (row.editor.hidden ? "none" : "unset"), float: "left", marginBottom: "0px", marginRight: "0px" }}
					inputStyle={{ textAlign: row.editor.txtStyle.textAlign }}
				/> {row.editor.postLabel}
				<span style={{ visibility: "hidden", lineHeight: "30px", display: "inline-block" }}>.</span>
			</span>);
		} else if (row.value === undefined || row.value === null || typeof (row.value) !== "string" || row.value.indexOf("\n") === -1) { //null and undefined tests are so it doens't try to do multiline
			return row.value;
		} else {
			return row.value.split("\n").map((line, idx) => { //break up multiline values
				return (<span key={"lst" + idx}>{line}<br /></span>);
			});
		}
	}

	if ((data && data.length === 0) || hidden === true) {
		if (!style) style = {};
		if (style.display !== "none") {
			style.display = "none";
		}
	}

	return (
		<DataList
			style={style}
			loading={loading}
			data={data}
			renderItem={renderItem}
			{...props}
		/>
	);
}

export const ListHorizontal = ({ itemStyle, ...props }) =>
	ListVertical({ ...props, itemStyle: { ...(itemStyle || {}), display: 'inline-block' } });

export const NoticeBox = ({ notices, setNotices, ...props }) => {
	const noticeTimeout = useRef(false);
	const fadeTime = 5;
	const { t, User } = useContext(SessionContext);

	useEffect(() => {
		if (!notices) return;

		//notices changed, kill timer 
		if (noticeTimeout.current) {
			clearTimeout(noticeTimeout.current);
			noticeTimeout.current = false;
		}
		//and restart it to look at new data, if there is any
		if (notices.some(n => n.expires)) {
			//setup fading removal
			noticeTimeout.current = setInterval(() => {
				//filter to remove expired
				const newitems = util.clone(notices).map(n => ({ ...n, delete: n.delete || (n.expires && moment().isAfter(n.expires)) }));
				setNotices(newitems);
			}, 500);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notices]);

	function parseParam(param) {
		if (!param) return param;
		let res = {};
		let i = 1;
		param.forEach(p => {
			let v = p.split(":");
			//if param is prefixed with N, its a N:val:places:label as passed to util.measure()
			if (v[0] === "N") {
				res["var" + i] = util.measure(v[1], User.units_pref, v[2], v[3]);
			} else {
				res["var" + i] = p;
			}
			i++;
		});
		return res;
	}

	if (!notices || notices.length === 0) {
		return null;
	} else {
		return (<div style={props.style}>
			{notices.filter(n => !n.delete).map((value, index) => {
				let s = {}, c = "";
				if (value.expires) {
					c = "fadeout";
					if (value.expires.diff(moment(), "seconds") - fadeTime > 0) {
						s = { animationDelay: (value.expires.diff(moment(), "seconds") - fadeTime + 1) + "s" };
					}
				}
				return (<div id={"wrn" + index} key={"wrn" + index} className={"noticebox " + c} style={s} >
					{t("err_" + value.type)}:
					{t(value.notice, parseParam(value.tparam))}
				</div>);
			})
			}
		</div>);
	}
}

export const Validators = () => {
	const { t } = useContext(SessionContext);
	return {
		none: {
			validator: function validator() {
				return true;
			},
			message: ""
		},
		required: {
			validator: function validator(value) {
				return null !== value && undefined !== value && ("boolean" == typeof value ? value : String(value).trim().length > 0);
			},
			message: t("err_field_req")
		},
		enum: {
			validator: function validator(value, param) {
				for (let i = param.length; i < 10; i++) { //make sure there's always 10 options for the translation
					param.push('');
				}
				return null !== value && param.includes(String(value));
			},
			message: t("err_field_enum")
		},
		length: {
			validator: function validator(e, t) {
				var n = e ? String(e).trim().length : 0;
				return n >= t[0] && n <= t[1];
			},
			message: "Text number between {0} and {1} characters long."
		},
		email: {
			validator: function validator(e) {
				return /^[A-Za-z0-9]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,15})$/.test(e);
			},
			message: "Please enter a valid email address."
		},
		url: {
			validator: function validator(e) {
				return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(e);
			},
			message: "Please enter a valid URL."
		},
		passequals: {
			validator: function validator(value, param) {
				return null !== value && param[0] === value;
			},
			message: t("err_passwd_mismatch")
		},
		int: {
			validator: (value) => {
				if (isNaN(value)) {
					return false;
				} else if (parseInt(value) !== parseFloat(value)) {
					return false;
				}
				return true; //only gets here if all is OK
			},
			message: t("err_validateint")
		},
		intrange: {
			validator: (value, param) => {
				if (isNaN(value)) {
					return false;
				} else if (parseInt(value) !== parseFloat(value)) {
					return false;
				} else {
					if (parseInt(value) < param[0] || parseInt(value) > param[1]) {
						return false;
					}
				}
				return true; //only gets here if all is OK
			},
			message: t("err_validrange")
		},
		float: {
			validator: (value) => {
				if (isNaN(value)) {
					return false;
				}
				return true; //only gets here if all is OK
			},
			message: t("err_validatefloat")
		},
		floatrange: {
			validator: (value, param) => {
				if (isNaN(value)) {
					param[0] = util.measure(param[0], param[2] || "si");
					param[1] = util.measure(param[1], param[2] || "si");
					return false;
				} else {
					if (util.round(value, 4) < param[0] || util.round(value, 4) > param[1]) {
						//console.log("floatrange 1", value, param);
						param[0] = util.measure(param[0], param[2] || "si");
						param[1] = util.measure(param[1], param[2] || "si");
						//console.log("floatrange 1", util.measure(value, param[2]||"si"), param);
						return false;
					}
				}
				return true; //only gets here if all is OK
			},
			message: t("err_validrange")
		},
		positive: {
			validator: (value) => {
				if (isNaN(value)) {
					return false;
				} else if (parseFloat(value) < 0) {
					return false;
				}
				return true; //only gets here if all is OK
			},
			message: t("err_positive")
		},
		minorzero: {
			validator: (value, param) => {
				if (isNaN(value)) {
					param[0] = util.measure(param[0], param[1] || "si");
					return false;
				} else if (parseFloat(value) < 0) {
					param[0] = util.measure(param[0], param[1] || "si");
					return false;
				} else if (parseFloat(value) > 0 && parseFloat(value) < parseFloat(param[0])) {
					param[0] = util.measure(param[0], param[1] || "si");
					return false;
				}
				return true; //only gets here if all is OK
			},
			message: t("err_minorzero")
		},
		gtzero: {
			validator: (value) => {
				if (isNaN(value)) {
					return false;
				} else if (parseFloat(value) <= 0) {
					return false;
				}
				return true; //only gets here if all is OK
			},
			message: t("err_gtzero")
		},
		dateset: {
			validator: (value, param) => {
				let compday = util.plainDate(value);
				return param.indexOf(compday) >= 0;
			},
			message: t("err_datena")
		},
	}
};

export class MeasureBox extends InputBase {
	//constructor(props) {
	//	super(props);
	//}

	handleInputChange(e) {
		this.setState({ textraw: e.target.value });
		if (e.target.value === "" || isNaN(e.target.value) || e.target.value.endsWith(".") || e.target.value.endsWith("0")) {
			this.props.debug && console.log("storing raw", this.state.focused, e.target.value)
			this.setValue(e.target.value);
		} else {
			this.props.debug && console.log("storing", this.state.focused, e.target.value, util.toStore(e.target.value, this.props.units))
			this.setValue(parseFloat(util.toStore(e.target.value, this.props.units)));
		}
	}

	text() {
		if (!this.state.focused && (this.props.fixedPlaces === undefined || this.state.text === "" || isNaN(parseFloat(this.state.text)))) {
			this.props.debug && console.log("showing text not focused", { focused: this.state.focused, textraw: this.state.textraw, text: this.state.text })
			return this.state.text;
		} else if (!this.state.focused) {
			this.props.debug && console.log("showing", this.state.focused, this.state.text, util.measure(this.state.text, this.props.units, this.props.fixedPlaces, false))
			return util.measure(this.state.text, this.props.units, this.props.fixedPlaces, false);
		} else {
			this.props.debug && console.log("showing raw/text focused", { focused: this.state.focused, textraw: this.state.textraw, text: this.state.text })
			return this.state.textraw || this.state.text;
		}
	}
	blur() {
		let self = this;
		self.props.debug && console.log("blur", { focused: self.state.focused, textraw: self.state.textraw, text: self.state.text })
		//these 2 didn't get converted, do it now
		if ((self.state.text.endsWith(".") || self.state.text.endsWith("0")) && self.props.fixedPlaces !== undefined && self.state.text !== "" && !isNaN(parseFloat(self.state.text))) {
			self.props.debug && console.log("blur setting value", util.toStore(self.state.text, self.props.units));
			self.setValue(parseFloat(util.toStore(self.state.text, self.props.units)));
		}
		setTimeout(() => { //delay to let value commit
			self.setState({ focused: false }, () => {
				self.context && self.context.fieldBlur && self.context.fieldBlur(self);
				self.props.onBlur && self.props.onBlur();
			});
		}, 100);
	}
	focus() {
		let self = this;
		self.setState({ focused: true, textraw: this.text() }, () => { //make sure raw text matches what's displayed before editing
			self.context && self.context.fieldFocus && self.context.fieldFocus(self);
			self.props.onFocus && self.props.onFocus();
		});
	}

	renderInput() {
		const props = {
			autoComplete: 'off',
			className: this.inputClasses(),
			style: this.props.inputStyle,
			value: this.text() || '',
			id: this.props.inputId,
			disabled: this.props.disabled,
			readOnly: this.props.readOnly || !this.props.editable,
			tabIndex: this.props.tabIndex,
			placeholder: this.props.placeholder,
			onFocus: this.focus.bind(this),
			onBlur: this.blur.bind(this),
			onChange: this.handleInputChange.bind(this),
			key: this.props.key || 1, // Satisfy the 'unique "key"' warning when it's not passed
		}
		return (
			<input {...props}></input>
		)
	}
}
