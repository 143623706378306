import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import 'index.css';
import { log } from 'data/logger';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import Cookies from 'js-cookie';

import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, Observable, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from "subscriptions-transport-ws";
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
//import { InMemoryCache } from 'apollo-cache-inmemory';
import introspectionQueryResultData from 'data/fragmentTypes.json';
import MessageTypes from 'subscriptions-transport-ws/dist/message-types';

import { SessionProvider } from 'data/Session';
import { autologin, util } from './data/shared';

const fragmentCache = new InMemoryCache({ possibleTypes: introspectionQueryResultData });

let authOK = false;
let wsOperations = {};
const wsclient = new SubscriptionClient((util.apihost() + "/graphql").replace("http", "ws"), {
	reconnect: false,
	lazy: true,
	connectionParams: async () => {
		return {"authorization": "Bearer " + await GetCookie()};
	},
	connectionCallback: async () => {
		//console.log("ws authOK", authOK, wsOperations)
		let token = await GetCookie();
		if(!authOK || !token) { //we don't have an auth session, disconnect and try latter
			wsOperations = {...{}, ...wsclient.operations}; //no worries on deep clone here
			//wsclient.close(true);
			return;
		}
		if(Object.keys(wsOperations).length) { //this only happens when the connection droped, make sure we resubscribe
			Object.keys(wsOperations).forEach(id => {
				if(wsOperations[id].options.variables.token) {
					wsOperations[id].options.variables.token = token; //update token
				}
				wsclient.sendMessage(
					id,
					MessageTypes.GQL_START,
					wsOperations[id].options
				);
			});	
			wsOperations = {};
		}
	},
});

wsclient.on('disconnected', () => {
	//console.log("websocket disconnect")
	if(Object.keys(wsclient.operations).length) {
		wsOperations = {...{}, ...wsclient.operations}; //no worries on deep clone here
	}
	//console.log("ws disconnected", wsOperations, wsclient.operations)
	authOK = false;
	//give server or connection a moment to recover than try a login
	let r = setInterval(async () => {
		if(!(await GetCookie())) { //not ready to autologin yet
			return;
		}
		//console.log("websocket relogin")
		autologin().then((tok)=> {
			if(tok) {
				authOK = true;
				clearInterval(r);
				setTimeout(() => {
					if(wsclient.status === 3) { //only if closed
						wsclient.connect();
					}
				}, 10);
				
			} else {
				Cookies.set('x-token', '');//login failed, token must be bad.  clear and keep trying until it's valied
			}
		});
	}, 2000);
});

wsclient.on('reconnected', () => {
	//console.log("ws reconnected", wsOperations)
	// Push all current wsOperations to the new connection
	if(Object.keys(wsOperations).length) { //this only happens when the connection droped, make sure we log back in
		Object.keys(wsOperations).forEach(id => {
			wsclient.sendMessage(
				id,
				MessageTypes.GQL_START,
				wsOperations[id].options
			);
		});	
		wsOperations = {};
	}
	
});

let inReAuth = false;
async function sleep(ms) {
	return new Promise(resolve => {
		setTimeout(() => {
			resolve();
		}, ms);
	});
}
async function GetCookie() {
	while (inReAuth) {
		await sleep(100);
	}
	return Cookies.get("x-token");
}

const wsLink = new WebSocketLink(wsclient);
const client = 
	new ApolloClient({
		link: ApolloLink.from([
			onError(({ graphQLErrors, networkError, operation, forward }) => {
				if (graphQLErrors) {
					let NeedAuth = false;
					for (let err of graphQLErrors) {
						// handle errors differently based on its error code
						switch (err.extensions.code) {
							case 'UNAUTHENTICATED':
							case 'FORBIDDEN': 
								NeedAuth = true; //move reauth outside loop
								authOK = false;
								break;
							default:
								log.error("_","ApolloLink Error %O", err);
							//don't return anything, just let the operation stop
						}
					}
					if (NeedAuth) {
						// old token has expired throwing AuthenticationError,
						// one way to handle is to obtain a new token and 
						// add it to the operation context
						const headers = operation.getContext().headers;
						inReAuth = true;
						log.warn("auth","doing reauth");
						// Now, pass the modified operation to the next link
						// in the chain. This effectively intercepts the old
						// failed request, and retries it with a new token

						return new Observable(observer => {
							autologin()
								.then((tok) => {
									if(tok) {
										log.trace("auth","tok",tok);
										operation.setContext({
											headers: {
												...headers,
												"Authorization": "Bearer " + tok,
											},
										});
									}
								})
								.then(() => {
									const subscriber = {
										next: observer.next.bind(observer),
										error: observer.error.bind(observer),
										complete: observer.complete.bind(observer)
									}
									inReAuth = false;
									log.trace("auth","forwarding");
									authOK = true;
									// Retry last failed request
									forward(operation).subscribe(subscriber)
								})
								.catch(error => {
									// No refresh or client token available, we force user to login
									inReAuth = false;
									observer.error(error)
								})
						});
					}
				} else if(networkError) {
					log.error("_", "ApolloLink networkError %O", networkError);
				}
			}),
			new ApolloLink((operation, forward) =>
				new Observable(observer => {
					let handle;
					Promise.resolve(operation)
						.then(async operation => {
							operation.setContext({
								headers: {
									"Authorization": "Bearer " + await GetCookie()
								}
							});
						})
						.then(() => {
							handle = forward(operation).subscribe({
								next: observer.next.bind(observer),
								error: observer.error.bind(observer),
								complete: observer.complete.bind(observer),
							});
						})
						.catch(observer.error.bind(observer));

					return () => {
						if (handle) handle.unsubscribe();
					};
				})
			),
			split(
				({ query }) => {
					const definition = getMainDefinition(query);
					return (
						definition.kind === 'OperationDefinition' &&
						definition.operation === 'subscription'
					);
				},
				wsLink,
				new HttpLink({
					uri: util.apihost() + "/graphql"
				})
			)
		]),
		cache: fragmentCache,
	});

function LoadingScreen() {

	return (
		<div style={{ padding: "10px", fontSize: "22px" }}>
			<img className="fa-spin" alt="Loading..." src="/logo.svg" /><br />
			Loading...
		</div>

	);
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Suspense fallback={(<LoadingScreen />)}>
		<ApolloProvider client={client}>
			<BrowserRouter>
				<SessionProvider>
					<App />
				</SessionProvider>
			</BrowserRouter>
		</ApolloProvider>
	</Suspense>
);
//, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
