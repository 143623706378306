import React, { useContext, useEffect, useState, lazy, } from 'react';
import { withTranslation } from 'react-i18next';
import { Route, Routes, } from "react-router-dom";
import i18n from 'i18next';
import { log } from './data/logger';

import { LocaleProvider, Layout, LayoutPanel, SideMenu, LinkButton } from 'rc-easyui';
import easyuien from 'rc-easyui/dist/locale/easyui-lang-en';
import easyuies from 'rc-easyui/dist/locale/easyui-lang-es';
import moment from 'moment';

import { AcctMenu, CustMenu, LangMenu, UnitMenu } from './Widgets/AppMenu';
import { ChatWindow } from './Widgets/Dialog';
import { SessionContext } from './data/Session';
import { util } from './data/shared';
import Cookies from 'js-cookie';
import config from './config.json';

import { ToastContainer } from 'react-toastify';

const AcctAdmin = lazy(() => import('pages/AcctAdmin'));
const AcctPrefs = lazy(() => import('pages/AcctPrefs'));
const AcctPress = lazy(() => import('pages/AcctPress'));
const AcctReset = lazy(() => import('pages/AcctReset'));
const Billing = lazy(() => import('pages/Billing'));
const Brochures = lazy(() => import('pages/Brochures'));
const Contacts = lazy(() => import('pages/Contacts'));
const CompatLink = lazy(() => import('pages/CompatLink'));
const ErrorReport = lazy(() => import('pages/ErrorReport'));
const Files = lazy(() => import('pages/Files'));
const Forms = lazy(() => import('pages/Forms'));
const Go = lazy(() => import('pages/Go'));
const Help = lazy(() => import('pages/Help'));
const Login = lazy(() => import('pages/Login'));
const Materials = lazy(() => import('pages/Materials'));
const Order = lazy(() => import('pages/OrderForms'));
const Orders = lazy(() => import('pages/Orders'));
const OrderDetail = lazy(() => import('pages/OrderDetail'));
const Favorites = lazy(() => import('pages/Favorites'));
const Quote = lazy(() => import('pages/QuoteForms'));
const Quotes = lazy(() => import('pages/Quotes'));
const RetoolQuote = lazy(() => import('pages/RetoolQuote'));
const Search = lazy(() => import('pages/Search'));
const Summary = lazy(() => import('pages/Summary'));
const Taper = lazy(() => import('pages/Taper'));
const News = lazy(() => import('pages/News'));

const easyuilangs = {
	en: easyuien,
	es: easyuies
}

const routes = [
	{ path: "/login", element: <Login />, public: true },
	{ path: "/summary", element: <Summary />, },
	{ path: "/contacts", element: <Contacts />, },
	{ path: "/errrep", element: <ErrorReport />, },
	{ path: "/files", element: <Files />, },
	{ path: "/brochures", element: <Brochures />, public: true },
	{ path: "/forms", element: <Forms />, public: true },
	{ path: "/help", element: <Help />, },
	{ path: "/search", element: <Search />, },
	{ path: "/orders", element: <Orders />, },
	{ path: "/order/:prod_code", element: <Order />, },
	{ path: "/orderdetail/:work_order", element: <OrderDetail />, },
	{ path: "/favorites", element: <Favorites />, },
	{ path: "/materials", element: <Materials />, },
	{ path: "/billing", element: <Billing />, },
	{ path: "/quotes", element: <Quotes />, },
	{ path: "/quote/:prod_code", element: <Quote />, },
	{ path: "/retoolquote", element: <RetoolQuote />, },
	{ path: "/taper", element: <Taper />, },
	{ path: "/news", element: <News />, },
	{ path: "/track.php", element: <CompatLink />, public: true },
	{ path: "/go/:action", element: <Go />, public: true },
	{ path: "/account/admin", element: <AcctAdmin />, },
	{ path: "/account/prefs", element: <AcctPrefs />, },
	{ path: "/account/press", element: <AcctPress />, },
	{ path: "/account/reset/:token", element: <AcctReset />, public: true },
];

let rfSidebar;

function AuthRequired({ element }) {
	const { isAuth } = useContext(SessionContext);
	return isAuth ? element : <Login />;
}

//compat errors to ignore that render fine anyway
const elist = [
	"Cannot read properties of null (reading 'body')", //this is internal somewhere, likely inside the apollo client, but given the 2 below maybe not
	"this.view2 is null", //firefox generated, possibly in datagrid?
	"null is not an object (evaluating 'this.view2.body')", //mac generated, also in datagrid?
	"Cannot read property 'body' of null", //chrome?
];
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, displaySent: "none" };
	}

	
	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		if(elist.indexOf(error.message) >= 0) {
			console.warn("Ignoring \"" + error.message + "\"");
			return {hasError: false}; 
		} else if (error.message.indexOf("Loading chunk") >= 0) {
			log.error("_", "chunk 404 reload %O", error);
			window.location.reload();
			return { hasError: false };
		} else if (window.location.href.endsWith(":prod_code")) {
			log.error("_", "prod_code reload %O", error);
			window.location.href = window.location.href.replace("/:prod_code", "s"); //quote to quotes
			return { hasError: false };
		} else if (util.isdevsystem()) {
			return {hasError: false};
		} else
			return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		let err = {
			error: error.message,
			location: window.location.href,
			user: {
				customer_number: this.context.User.customer_list[this.context.User.customer_index].customer_number,
				login_source: this.context.User.login_source,
				web_login: this.context.User.web_login,
			},
			errorInfo: errorInfo.componentStack,
		};

		if(elist.indexOf(error.message) >= 0) {
			console.warn("Ignoring \"" + error.message + "\"");
		} else if (util.isdevsystem()) {
			//window.alert("Error trapped, see console");
			log.error("_", "ErrorBoundary %O", err)
		} else {
			if (error.message.indexOf("Loading chunk") >= 0) {
				log.error("_", "chunk 404 reload %O", err);
				window.location.reload();
			} else if (window.location.href.endsWith(":prod_code")) {
				log.error("_", "prod_code reload %O", err);
				window.location.href = window.location.href.replace("/:prod_code", "s"); //quote to quotes
			} else {
				err.hist = log.getHist();
				fetch(util.apihost() + '/?senderr=1', {
					method: 'post',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(err)
				})
					.then(() => {
						this.setState({
							displaySent: "unset"
						});
					})
					.catch((reason) => {
						log.error("_", "error sending report %O", reason);
					});
			}
		}
	}

	render() {
		if (this.state.hasError) {
			// Error path
			return (
				<div>
					<h2>Something went wrong.  Reload page to try again.</h2>
					<div>You may report the issue in the Account menu to help of prevent it from happening again.</div>
					<div style={{ display: this.state.displaySent }}>Error has been logged for review.</div>
					<hr />
					<h2>Algo salió mal. Por favor vuelve a cargar la página para intentarlo de nuevo.</h2>
					<div>Puede informar el problema en el menú de su cuenta para ayudar a evitar que vuelva a suceder.</div>
					<div style={{ display: this.state.displaySent }}>Se ha registrado el error para su revisión.</div>
				</div>
			);
		}
		// Normally, just render children
		return this.props.children;
	}
}

function App() {
	const { ChatToggle, escClose, GotoPage, isAuth, keepalive, pageTitle, bodyWidth, setBodyWidth, SideMenuItems, SideMenuSelected, t } = useContext(SessionContext);
	const [state, setState] = useState({
		title: 'My Wilson MFG',
		shorttitle: 'myWMFG',
		screen: { isSmall: false, manual: false, collapsed: false },
		easyuilocale: easyuien
	});

	useEffect(() => {
		setTimeout(() => {
			screenToggle(i18n.language);
		}, 100);

		function handleResize() {
			setTimeout(() => {
				screenToggle(i18n.language);
			}, 100);
		}
		window.addEventListener('resize', handleResize);

		i18n.on('languageChanged', function (lng) {
			screenToggle(lng);
		});
		document.addEventListener("keydown", (e) => {
			if (e.code === "Escape") {
				escClose?.close();
			}
		});

		keepalive(); //initial one gets version
		setInterval(() => {
			keepalive();
		}, 15 * 60 * 1000);

		return () => window.removeEventListener('resize', handleResize);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function screenToggle(lng, doCollapse) {
		let didChange = false;
		let newstate = util.clone(state);
		if ((window.document.body.clientWidth < 600) !== newstate.screen.isSmall) {
			didChange = true;
			newstate.screen.isSmall = (window.document.body.clientWidth < 600)
		}

		if (doCollapse) { //force a toggle
			newstate.screen.collapsed = !newstate.screen.collapsed;
			newstate.screen.manual = true;
			didChange = true;
		} else { //toggle based on screen size
			if (window.document.body.clientWidth > 1100 && newstate.screen.collapsed) {
				newstate.screen.collapsed = false;
				didChange = true;
			} else if (window.document.body.clientWidth < 950 && !newstate.screen.collapsed) {
				newstate.screen.collapsed = true;
				didChange = true;
			}
		}

		if (state.easyuilocale !== easyuilangs[lng.split("-")[0]]) {
			moment.locale(lng);
			newstate.easyuilocale = easyuilangs[lng.split("-")[0]];
			didChange = true;
		}

		if (didChange) {
			setState(newstate);
		}
		if(bodyWidth !== window.document.body.clientWidth - rfSidebar.clientWidth - 20) {
			setBodyWidth(window.document.body.clientWidth - rfSidebar.clientWidth - 20);
		}
	}

	function toggle() {
		screenToggle(i18n.language, true);
	}

	function SideBarClick(item) {
		switch (item.type) {
			case "url":
				window.location.replace = item.url;
				//this.context.setPageTitle(item.text);
				break;
			case "nav":
				//nav(item.path);
				GotoPage(item.path);
				break;
			default:
				log.trace("_", "unknown SideBarClick %O", item)
		}
	}

	return (
		<ErrorBoundary>
			<LocaleProvider local={state.easyuilocale}>
				<div>
					<div className="f-column">
						<div className="f-row">
							<div className="f-row f-full">
								<div className="main-bar f-full noprint">
									<Layout>
										<LayoutPanel bodyCls="main-header" region="west" border={false}>
											<span className="main-toggle fas fa-bars" onClick={toggle}></span>
											<span className="main-title"
												style={(state.screen.collapsed
													? { lineHeight: "normal", fontSize: "12px", paddingTop: "12px" }
													: {})}
											>{state.screen.collapsed ? state.shorttitle : state.title}</span>
										</LayoutPanel>
										<LayoutPanel bodyCls="main-center" region="center" border={false}>
											<div className="main-title" style={{ color: "white" }}>{pageTitle}</div>
										</LayoutPanel>
										<LayoutPanel region="east" border={false} style={{ lineHeight: 'normal', textAlign: "right" }}>
											<div className="main-title main-bar" style={{ float: "left", display: (isAuth ? "inherit" : "none") }}>
												<LinkButton iconCls="far fa-comments" style={{ color: "rgb(255, 152, 0)", width: "fit-content", fontWeight: "bold" }} plain iconAlign="top" onClick={ChatToggle} >{t("lbl_helpchat")}</LinkButton>
											</div>
											<div style={{ float: "left" }}>
												<div style={{ width: "100%", textAlign: "center" }}>
													<CustMenu smallscreen={state.screen.isSmall} style={{ height: "23px" }} />
												</div>
												<LangMenu smallscreen={state.screen.isSmall} style={{ height: "23px" }} />
												<UnitMenu smallscreen={state.screen.isSmall} style={{ height: "23px" }} />
												<AcctMenu smallscreen={state.screen.isSmall} style={{ height: "23px" }} />
											</div>
										</LayoutPanel>
									</Layout>
								</div>
							</div>
						</div>
						<div className="f-row f-full">
							<div ref={ref => rfSidebar = ref} className="sidebar-body f-animate noprint" style={{ paddingRight: "5px" }}>
								<SideMenu
									data={SideMenuItems}
									border={false} collapsed={state.screen.collapsed}
									onItemClick={SideBarClick}
									selection={SideMenuSelected}
								/>
							</div>
							<div className="main-body f-full">
								<Routes>
									<Route path="/" element={<AuthRequired element={(Cookies.get("seenv") !== config.version ? <News /> : <Summary />)} path={(Cookies.get("seenv") !== config.version ? "/news" : "/summary")} />} /> {/* summary or what's new */}
									<Route path="/login" element={<Login />} />
									{routes.map(r => {
										if (r.public) {
											return <Route key={r.path} path={r.path} element={r.element} />
										} else {
											return <Route key={r.path} path={r.path} element={<AuthRequired element={r.element} path={r.path} />} />
										}
									})}
									<Route path='/pay' element={<Payment />}/>
									<Route path="*" element={<AuthRequired element={(Cookies.get("seenv") !== config.version ? <News /> : <Summary />)} path={(Cookies.get("seenv") !== config.version ? "/news" : "/summary")} />} /> {/* summary or what's new */}
								</Routes>
							</div>
						</div>
					</div>
					<ToastContainer position="top-center" theme="colored" autoClose={false} style={{ width: "auto", minWidth: "300px", maxWidth: "50%" }} />
					<ChatWindow className="noprint" />
				</div>
			</LocaleProvider>
		</ErrorBoundary>
	);
}

function Payment() {
	window.location.replace('https://simplepay.basysiqpro.com/?id=64cbf6972637837b23533ae8' + window.location.search?.replace("?","&"));
	return null;
}

ErrorBoundary.contextType = SessionContext;

export default withTranslation()(App);
