import React, { useEffect, useRef, useContext, useState, useReducer, useLayoutEffect } from 'react';
import { DataGrid, Dialog, GridColumn, LinkButton, Messager as EasyMessager, MessagerDialog, Tooltip } from 'rc-easyui';
import { SessionContext } from '../data/Session';
import { Trans } from 'react-i18next';
import { gql, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { ListVertical, CheckGrid } from './Forms';
import { ChatBox } from 'react-chatbox-component';
import { toast } from 'react-toastify';
import { pureRecursiveMerge, util } from '../data/shared';

export class MyMessagerDialog extends MessagerDialog {
	renderPrompt() {
		if(this.props.messagerType==="prompt") {
			return (
				<input ref={ref => {this.input = ref} }
					className="messager-input" 
					value={this.state.inputValue}
					onChange={(e)=> {
						this.setState({
							inputValue: e.target.value
						})					
					}}
				/>
			);
		} else if(this.props.messagerType==="select" && !this.props.multiple) {
			return (
				<DataGrid ref={ref => {this.inputgrid = ref} }
					data={this.props.selectOptions}
					selectionMode="single"
					showHeader={this.props.columns.length > 1}
					onRowSelect={row=> {
						this.setState({ selection: row });
					}}
					onRowDblClick={row=>{
						this.setState({ resultValue:true, selection: row });
						this.close();
					}}
					selection={this.state.selection}
				>
					{this.renderSelectColumns()}
				</DataGrid>
			);
		} else if(this.props.messagerType==="select" && this.props.multiple) {
			//wait until div renders
			if(!this.foc) {
				this.foc = setInterval(() => {
					if(this.keydiv){
						clearInterval(this.foc);
						this.foc = false;
						this.keydiv.focus();      
					}
				}, 500);
			}
			return [
				<div key={0} ref={ref=>this.keydiv=ref} tabIndex={0}>
					<CheckGrid ref={ref => {this.inputgrid = ref} } tabIndex={0}
						data={this.props.selectOptions}
						showHeader={this.props.columns.length > 1}
						onSelectionChange={selection=> {
							this.setState({ selection });
						}}
						selection={this.state.selection}
					>
						{this.renderSelectColumns()}
					</CheckGrid>
				</div>,
				<div key={1} style={{fontSize:12}}>Hold the CTRL key to choose more than one item</div>,
			];
		} else {
			return null;
		}
	}

	renderSelectColumns() {
		return this.props.columns.map(s=>(<GridColumn key={"sel_col_"+s.field} {...s} />));
	}

	renderContent() {
		const { msg, title } = this.props;
		return [
			<div key="content" style={{ textAlign: "center" }}>			
				<h2>{title}</h2>
				<p><Trans>{msg}</Trans></p>
				{this.renderPrompt()}
			</div>,
			this.renderButtons()
		]
	}
}
export class Messager extends EasyMessager {
	onOpen() {
		let iv = setInterval(() => {
			if(this.dialog) {
				clearInterval(iv);
				this.dialog.vcenter();
				if(this.dialog.props.messagerType==="select") {
					this.dialog.setState({selection: this.state.defaultValue})
				} else {
					this.dialog.setState({inputValue: this.state.defaultValue})
				}
			}
		}, 50);
	}

	async alertSync(e) {
		return new Promise(resolve => {
			if(typeof e === "string") {
				e = {
					title:"Notice",
					msg: e
				};
			}
			this.alert({
				...e,
				result: r=>{
					resolve(r);
				}
			});
		}).then(r=>{
			if(e.result) { //if result is provided, call it, and if it returns a value, return that instead
				let x = e.result(r);
				if(x !== undefined) {
					return x;
				}
			}
			return r;
		});
	}

	async confirmSync(e) {
		return new Promise(resolve => {
			if(typeof e === "string") {
				e = {
					title:"Confirm",
					msg: e
				};
			}
			this.confirm({
				...e,
				result: r=>{
					resolve(r);
				}
			});
		}).then(r=>{
			if(e.result) { //if result is provided, call it, and if it returns a value, return that instead
				let x = e.result(r);
				if(x !== undefined) {
					return x;
				}
			}
			return r;
		});
	}

	async promptSync(e) {
		return new Promise(resolve => {
			if(typeof e === "string") {
				e = {
					title:"Input",
					msg: e
				};
			}
			this.prompt({
				...e,
				result: r=>{
					resolve(r);
				}
			});
		}).then(r=>{
			if(e.result) { //if result is provided, call it, and if it returns a value, return that instead
				let x = e.result(r);
				if(x !== undefined) {
					return x;
				}
			}
			return r;
		});
	}

	async selectSync(e) {
		let defProps = {
			title:"Select",
			msg:"",
			buttons:[{text:"Select",value:true},{text:"Cancel",value:false}],
			columns:[],
			selectOptions:[],
			defaultValue:[],
			multiple:false
		};
		return new Promise(resolve => {
			
			e.buttons && e.buttons.length || (e.buttons = [{
				text: "Select",
				value: !0
			}, {
				text: this.t("Messager.cancel", this.props.defaultCancel),
				value: !1
			}]);
			this.openDialog({
				...defProps, //make sure everything has a reset value
				...e, //set specific values
				result: r=>{
					resolve(r);
				}
			}, "select");
		}).then(r=>{
			if(e.result) { //if result is provided, call it, and if it returns a value, return that instead
				let x = e.result(r);
				if(x !== undefined) {
					return x;
				}
			}
			if(!r) {
				if(Array.isArray(this.dialog.state.selection)){
					this.dialog.setState({selection:[]});
				} else {
					this.dialog.setState({selection:{}});
				}
				return false;
			}else {
				let sel = false;
				if(Array.isArray(this.dialog.state.selection)){
					sel = this.dialog.state.selection.slice();
					this.dialog.setState({selection:[]});
				} else {
					sel = util.clone(this.dialog.state.selection);
					this.dialog.setState({selection:{}});
				}
				return sel;
			}
		});
	}

	render() {
		return (<div>
			<MyMessagerDialog
				ref={ref => this.dialog = ref}
				showHeader={false}
				borderType="none"
				autoCenter
				onOpen={this.onOpen.bind(this)}
				multiple={false}
				{...this.props}
				{...this.state}
			/>
		</div>)
	}
}

//make sure parent watches the session escClose and zeros its URL vars or a reopen of the same won't work
export const FileViewer = ({ FileUrl, loading, sizepercent, ...rest }) => {
	const { t, BindEscClose } = useContext(SessionContext);
	const [closed, setClosed] = useState(true);
	const eref = useRef();
	if (!sizepercent) sizepercent = 50;

	useEffect(() => {
		if (FileUrl !== "" && eref.current !== null) {
			BindEscClose(eref.current);
			setClosed(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [FileUrl]);

	useEffect(() => {
		if(loading) {
			BindEscClose(eref.current);
			setClosed(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading])

	function onClose() {
		setClosed(true);
		BindEscClose(null);
		if(rest.onClose) rest.onClose();
	}

	function popOut() {
		window.open(FileUrl, 'MyWMFG', "scrollbars=no,status=no,location=no,toolbar=no,menubar=no");
		onClose();
	}

	function header() {
		return (
			<div key="3">
				<div key="4" className="panel-title">{rest.title}</div>
				<LinkButton key="5" style={{ position: "absolute", right: "20px", top: "0px", height: "19px" }} plain onClick={popOut}>
					<i key="6" className="fas fa-external-link-alt"></i>
				</LinkButton>
			</div>
		)
	}

	return (
		<Dialog key="0"
			ref={eref}
			style={{ top: (window.scrollY + 30) + "px", width: (window.innerWidth / (100 / sizepercent)) + "px", height: (window.innerHeight / (100 / sizepercent)) + "px" }}
			bodyCls="f-column"
			header={header}
			closed={closed}
			onClose={onClose}
			resizable={true}
			draggable={true}
			{...rest}
		>
			<div key="1" style={{ fontSize:"14px", padding: "60px", width: "500px", display:(loading?"unset":"none") }} >
				<img key="1" className="fa-spin" alt={t("lbl_loading") + "..."} src="/logo.svg"  /><br/><br/>
				{t("lbl_loading") + "..."}
			</div>
			<iframe key="2" title="File" src={FileUrl} className="f-full" style={{display:(loading?"none":"unset")}} />
		</Dialog>
	);
}

//make sure parent watches the session escClose and zeros its WorkOrder vars or a reopen of the same won't work
export const TrackOrder = ({ WorkOrder, loading, sizepercent, ...rest }) => {
	const client = useApolloClient();
	const { t, APIError, BindEscClose } = useContext(SessionContext);
	const [closed, setClosed] = useState(true);
	const [trackData, setTrackData] = useState({ship_via: t("lbl_loading")});
	const eref = useRef();
	if (!sizepercent) sizepercent = 50;

	const [trackOrder] = useLazyQuery(gql`
		query ($work_order: String) {
			order_track (work_order: $work_order) {
				ship_via
				pronumber
				pickupDate
				ponumber
				totalpieces
				totalweight
				signedby
				delivered
				ship_via_name
				lastActivity {
					location
					description
					date
				}
				website {
					title
					action
					method
					fields {
						name
						value
					}
				}
			}
		}
	`, { client, onError: APIError, fetchPolicy:"no-cache", onCompleted:(resp) => {
		if(resp.order_track.pickupDate) {
			setTrackData(resp.order_track);
		} else {
			setTrackData({...resp.order_track, pickupDate: t("lbl_notfound")});
		}
	} })


	useEffect(() => {
		if (WorkOrder !== "" && eref.current !== null) {
			trackOrder({ variables:{ work_order: WorkOrder } });
			BindEscClose(eref.current);
			setClosed(false);
		} else {
			setTrackData({ship_via: t("lbl_loading")});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [WorkOrder]);

	useEffect(() => {
		if(loading) {
			BindEscClose(eref.current);
			setClosed(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading])

	function onClose() {
		setClosed(true);
		BindEscClose(null);
	}

	return (
		<Dialog key="0"
			ref={eref}
			style={{ top: (window.scrollY + 30) + "px", width: (window.innerWidth / (100 / sizepercent)) + "px", height: (window.innerHeight / (100 / sizepercent)) + "px" }}
			bodyCls="f-column"
			closed={closed}
			onClose={onClose}
			resizable={true}
			draggable={true}
			{...rest}
		>
			<div>
				<i className={ship_to_icon(trackData.ship_via)} style={{ fontSize:"72px", float:"left"}} />
				<ListVertical
					labelStyle={{ width: 200 }}
					data={[
						{ title: t("lbl_workorder"), value: WorkOrder },
						{ title: t("lbl_shipvia"), value: trackData.ship_via_name },
						{ title: t("lbl_trackno"), value: trackData.pronumber },
						{ title: t("lbl_purorder"), value: trackData.ponumber },
						{ title: t("lbl_totpiece"), value: trackData.totalpieces },
						{ title: t("lbl_totweight"), value: trackData.totalweight },
						{ title: t("lbl_pudate"), value: trackData.pickupDate },
						{ title: t("lbl_deliver"), value: trackData.delivered },
						{ title: t("lbl_signed"), value: trackData.signedby },
					]}
				/>
				<LinkButton style={(trackData.website && trackData.website.title ? {} : {display:"none"})}
					onClick={() => {
						post_to_url(trackData.website);
					}
				} >{t("lbl_trackextern", {var1: trackData.website && trackData.website.title})}</LinkButton>
			</div>
		</Dialog>
	);
}

const ship_to_icon = (ship_via) => {
	switch(ship_via.substring(0,3)) {
		case "UPS":
			return "fab fa-ups";
		case "FEX":
			return "fab fa-fedex";
		case "DHL":
			return "fab fa-dhl";
		default:
			switch(ship_via) {
				case "FX TRUCK":
					return "fab fa-fedex";
				default:
					return "fas fa-truck";
			}
	}
}

const post_to_url = ({action, method, fields}) => {
    //internal redirect, get type only
	if(method.toLowerCase() === "get" //now walk the tree to make sure we're really ready 
		&& typeof window.chrome != 'undefined'
		&& typeof window.chrome.webview != 'undefined'
		&& typeof window.chrome.webview.hostObjects	!= 'undefined'
		&& typeof window.chrome.webview.hostObjects.external != 'undefined' 
		&& typeof window.chrome.webview.hostObjects.external.TrackingExternal != 'undefined'
	) {
		window.chrome.webview.hostObjects.external.TrackingExternal(JSON.stringify({
			action,
			fields
		}));
	} else {
		let form = document.createElement("form");
		form.setAttribute("method", method);
		form.setAttribute("action", action);
		form.setAttribute("target", "_new");

		fields.forEach(f=>{
			let field = document.createElement("input");
			field.setAttribute("type", "hidden");
			field.setAttribute("name", f.name);
			field.setAttribute("value", f.value);
			form.appendChild(field);
		});

		document.body.appendChild(form);
		form.submit();
	}
}

const chatmessage_fields = `
	id
	avail_users
	date_created
	date_closed
	user_id
	customer_name
	full_name
	date_added
	message
`;
export const ChatWindow = ({ ...rest }) => {
	const eref = useRef();
	const messager = useRef();
	const chatAlert = useRef();
	const client = useApolloClient();
	const { t, APIError, User, ChatToggle, chatprops, XToken } = useContext(SessionContext);
	const [chatmessages, setChatMessages] = useReducer(chatDataReducer, null, () => []);
	const [chatID, setChatID] = useState("");
	const [notifyButtonStyle, setNotifyButtonStyle] = useState({display:"none"});
	const [windowSize, setWindowSize] = useState({
		current:"show",
		show: {
			height:315, width:285, 
			icon:"minimize"
		},
		hide: {
			width:150, height:28, 
			icon:"restore"
		}
	});
	const [chatReload, { subscribeToMore: subchat }] = useLazyQuery(gql`
		query {
			chat_active {
				${chatmessage_fields}
			}
		}
	`, {client, onError: APIError, fetchPolicy:"no-cache", onCompleted:(resp) => {
		if(resp.chat_active.length===0) {
			setChatID("");
			setChatMessages(null);
		} else {
			if(chatID === "") {
				setChatID(resp.chat_active[0].id)
			}
			setChatMessages(resp.chat_active.map(m => chatmsg_to_uimsg(m)));
			if(chatprops.closed) {
				ChatToggle();
			}
		}
	}});
	const [chatPost] = useMutation(gql`
		mutation ($message: NewMessage){
			chat_post(message:$message) {
				${chatmessage_fields}
			}
		}
	`, {client, onError: APIError, fetchPolicy:"no-cache", onCompleted:(resp) => {
		if(chatID === "") {
			setChatID(resp.chat_post.id);
		}
	}});
	const [chatClose] = useMutation(gql`
		mutation ($id: String){
			chat_close(id:$id) 
		}
	`, {client, onError: APIError, fetchPolicy:"no-cache", onCompleted:() => {
		setChatID("");		
		setChatMessages(null);
		ChatToggle();
	}});

	useLayoutEffect(() => {
		if(subchat && XToken) {
			const updUnsub = subchat({
				document: gql`
					subscription($token: String) {
						chat_message(token: $token) {
							${chatmessage_fields}
						}
					}
				`,
				variables: {token: XToken},
				updateQuery: (prev, { subscriptionData }) => {
					if(subscriptionData.data.chat_message.date_closed) {
						setChatID("");
						setChatMessages(null);
					} else {
						let newitems = [chatmsg_to_uimsg(subscriptionData.data.chat_message)]; //always an array
						if(subscriptionData.data.chat_message.avail_users === 0 && !chatmessages.some(m => m.user_id.endsWith("@wilsonmfg.com")||m.user_id.endsWith("@fcssd.com"))) { //if a wilson user has responded but is offline now, don't alert
							newitems.push({
								text: t("lbl_chatafterhours"),
								sender: {
									uid:'auto',
									avatar:'/logo.svg'
								}
							});
						}
						if(subscriptionData.data.chat_message.user_id !== User.web_login) {
							if (window.Notification && Notification.permission === "granted") {
								chatAlert.current = new Notification(t("lbl_chatalerttitle", {var1: subscriptionData.data.chat_message.full_name}), {
									tag:"chatalert", 
									body:subscriptionData.data.chat_message.message
								});
							}
						}
						setChatMessages(newitems);
					}
				}
			});

			return () => {
				updUnsub();
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subchat, XToken])

	React.useEffect(() => {
		if(window.Notification && Notification.permission !== "granted") {
			setNotifyButtonStyle({});
		}

		window.addEventListener('visibilitychange', onPageActive);
		return () => {
			window.removeEventListener('visibilitychange', onPageActive);
		};
	}, []);
  
  useEffect(() => {
		if(!User.web_login) {
			setChatMessages(null);
			setChatID("");
			if(!chatprops.closed) {
				ChatToggle();
			}
		} else {
			chatReload();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [User.web_login]);

	function onPageActive() {
		if (chatAlert.current && document.visibilityState === 'visible') {
			// The tab has become visible so clear the now-stale Notification.
			chatAlert.current.close();
		}
	}

	function onAskNotify() {
		if(window.Notification) {
			if (Notification.permission === "denied") {
				toast.info(t("lbl_chatalertdeny"), { autoClose: 10000 });
			} else {
				toast.info(t("lbl_chatalertperm"), { autoClose: 10000 });
				Notification.requestPermission(function(perm) {
					setNotifyButtonStyle(perm !== "granted" ?{}: {display: "none"});
				});
			}
		}
	}

	function onOpen() {
		if(chatmessages.length === 0) {
			setChatMessages([{
				text: t("lbl_chatintro"),
				sender: {
					uid:'auto',
					avatar:'/logo.svg'
				}
			}]);
			if (window.Notification && Notification.permission !== "denied") {
				Notification.requestPermission(function(perm) {
					setNotifyButtonStyle(perm !== "granted" ?{}: {display: "none"});
				});
			}
		}
	}

	function onClose() {
		if(!chatprops.closed) {
			messager.current.confirm({
				title: t("lbl_helpchat"),
				msg: t("lbl_closechat"),
				result: r => {
					if (r) {
						chatClose({variables:{id:chatID}});
					}
				}
			});
		}
	}

	function onSumbit(message) {
		chatPost({ variables: { message: {
			id: chatID,
			message: message
		}}});
	}

	function onResize({...size}) {
		setWindowSize(pureRecursiveMerge({show:size}, windowSize));
	}

	function onMinRestore() {
		if(windowSize.current==="show") {
			setWindowSize(pureRecursiveMerge({current:"hide"}, windowSize));
		} else {
			setWindowSize(pureRecursiveMerge({current:"show"}, windowSize));
		}
	}

	function header() {
		//window-restore
		return (
			<div key="1">
				<div className="panel-title">{t("lbl_helpchat")}</div>
				<LinkButton style={{ position: "absolute", right: "20px", top: "0px", height: "19px" }} plain onClick={onMinRestore}>
					<i className={"fas fa-window-"+windowSize[windowSize.current].icon}></i>
				</LinkButton>
				<LinkButton style={{ position: "absolute", right: "0px", top: "0px", height: "19px" }} plain onClick={onClose}>
					<i className="fas fa-times"></i>
				</LinkButton>
			</div>
		)
	}

	return (
		<div>
			<Dialog
				ref={eref}
				bodyCls="f-column"
				style={{ position:"fixed", top:(window.innerHeight-(windowSize[windowSize.current].height+10))+"px", left: (window.innerWidth-(windowSize[windowSize.current].width+20))+"px", height: windowSize[windowSize.current].height+"px", width: windowSize[windowSize.current].width+"px" }}
				header={header}
				{...chatprops}
				resizable={true}
				closable={false}
				onOpen={onOpen}
				onResize={onResize}
				{...rest}
			>
				<div style={notifyButtonStyle}>
					<LinkButton onClick={onAskNotify}>{t("lbl_chatalertperm")}</LinkButton>
					<Tooltip tooltipCls="form-field-focused" tooltipStyle={{maxWidth:"300px"}} position="top" content={t("lbl_chatalertpermhelp")}><i className={"fas fa-question-circle"} style={{ fontSize: "14px" }} /></Tooltip>
				</div>
				<ChatBox
					messages={chatmessages}
					user={{uid:User.web_login}}
					onSubmit={onSumbit}
				/>
			</Dialog>
			<Messager ref={messager} />
		</div>
	);
}
function chatDataReducer(chatmessages, items) {
	if (items === null) return []; //reset array
	//else append
	let newitems = util.clone(chatmessages);
	newitems.push(...items);
	return newitems;
}

const chatmsg_to_uimsg = (message) => {
	return {
		text: message.message,
		sender: {
			uid: message.user_id,
			name: message.full_name,
			avatar: message.user_id.endsWith("@wilsonmfg.com") ? '/logo.svg' : '/avatar.png'
		}
	}
}
