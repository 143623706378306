import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import config from 'config.json';
import { util } from "data/shared";

import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    whitelist:['en','es'],
    fallbackLng: 'en',
    //debug: true,
    detection: {
      order: ['localStorage','navigator'],
      checkWhitelist: true,
    },
    //debug: true,
    backend: {
      loadPath: util.apihost() + '/lang/{{lng}}',
      addPath: util.apihost() + '/lang/add/{{lng}}/{{ns}}',
      crossDomain: true,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: { //things to make Trans component work
      transSupportBasicHtmlNodes:true,
      transKeepBasicHtmlNodesFor:['br', 'strong', 'i', 'b', 'ul', 'ol', 'li', 'a'],
    }
  });

export default i18n;
