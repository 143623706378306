import React, { useEffect, useState, useReducer, useCallback, useRef, useLayoutEffect } from 'react';
import { useNavigate, } from "react-router-dom";
import { log } from 'data/logger';
import { stringify } from 'flatted';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { gql, useApolloClient, useMutation, useLazyQuery, useSubscription } from '@apollo/client';
import { doLogin, doLoginWilson, util } from 'data/shared';
import { treeHelper, LinkButton, Dialog, ButtonGroup } from 'rc-easyui';
import { SideBarMenu } from 'Widgets/AppMenu';
import { toast } from 'react-toastify';
import config from '../config.json';

export const SessionContext = React.createContext();
export const SessionConsumer = SessionContext.Consumer;

export const SessionProvider = ({ children }) => {
	const client = useApolloClient();
	const curCustIndex = useRef(0);
	const nav = useNavigate();
	const defaultUser = {
		full_name: '',
		eula: 0,
		customer_index: false,
		customer_list: [],
		order_disc: [],
		email: '',
		website_access: 0,
		web_login: '',
		web_version: 1,
		lang_pref: 'en',
		units_pref: 'si',
		login_source: 1, //default as regular user
		isSales: false,
		serverpath: { sep: "/", root: "Lw" }, //Lw is "/", server might replace this with "\"
	};
	const reducer = (state, newState) => ({ ...state, ...newState }); //for objects
	const { t: realT, i18n } = useTranslation();
	const [isAuth, setAuth] = useState(false);
	const [bodyWidth, setBodyWidth] = useState(100);
	const [escClose, BindEscClose] = useState(null);
	const [Token, setToken] = useState(Cookies.get("token") || '');
	const [XToken, setXToken] = useState(Cookies.get("x-token") || '');
	const [SideMenuItems, setSideMenuItems] = useState([]);
	const [SideMenuSelected, setSideMenuSelected] = useState(null);
	const [StayLogin, setStayLogin] = useState((Token === "" ? 0 : parseInt(Cookies.get("remember") || 0)));
	const [User, setUser] = useReducer(reducer, { ...{}, ...defaultUser });
	const [quoteProds, setQuoteProds] = useState([]);
	const [chatprops, setChatProps] = useState({ closed: true });
	const [pageTitle, setPageTitle] = useState("");
	const [searchFilters, setSearchFilters] = useState({page:""});

	const [updatesess] = useMutation(gql`
		mutation ($units_pref: String, $lang_pref: String, $customer_index: Int, $forcecchg: Boolean) {
			updatesess(units_pref: $units_pref, lang_pref: $lang_pref, customer_index: $customer_index, forcecchg: $forcecchg) {
				units_pref
				lang_pref
				customer_index
			}
		}
	`, { client, onError: APIError, onCompleted: (resp) => {
		updateUserPref(resp.updatesess);
	}});
	const [FetchProds] = useLazyQuery(gql`
		query {
			quoteprods
		}
	`, {
		client, onError: APIError, fetchPolicy: "no-cache", onCompleted: resp => {
			setQuoteProds(resp.quoteprods);
		}
	});
	const [currency_convert] = useLazyQuery(gql`
		query($inputs: [CurrencyInput]) {
			currency_convert(inputs:$inputs) {
				orig_value
				from_cur
				to_cur
				conv_date
				conv_value
			}
		}
	`, {
		client, onError: APIError, fetchPolicy: "no-cache"
	});

	const [VersionFetch, { subscribeToMore: subAuth }] = useLazyQuery(gql`
		query  {
			v
		}
	`, {
		client, onError: APIError, fetchPolicy: "no-cache", onCompleted: resp => {
			VersionCheck(resp.v)
		}
	});
	useSubscription(gql`
		subscription {
			version
		}
	`, {
		client,
		onSubscriptionData: (data) => {
			//console.log("subscription version", data.subscriptionData.data.version);
			VersionCheck(data.subscriptionData.data.version);
		}
	})

	const [logout] = useMutation(gql`
		mutation {
			logout 
		}
	`, { client, onError: APIError });

	const [KeepaliveFetch] = useLazyQuery(gql`
		query  {
			_
		}
	`, {
		client, onError: APIError, fetchPolicy: "no-cache", onCompleted: resp => {
			VersionCheck(resp._)
		}
	});
	const [sendlog] = useMutation(gql`
		mutation ($logMsg: logMsg){
			sendlog(logMsg:$logMsg)
		}
	`, { client, onError: APIError });

	useLayoutEffect(() => {
		if (subAuth && XToken) {
			const custUnsub = subAuth({
				document: gql`
					subscription($token: String) {
						customerchange(token: $token) {
							event
							json
						}
					}
				`,
				variables: { token: XToken },
				updateQuery: (prev, { subscriptionData }) => {
					if (subscriptionData.data.customerchange.event === "custidx") {
						let msg = JSON.parse(subscriptionData.data.customerchange.json);
						//compare the cookie token because another tab might have changed it.
						if ((msg.token === Cookies.get("x-token")) && msg.customer_index !== curCustIndex.current) {
							toast.warn(() => <div>
								<span>{t("lbl_custcngwarn", {cname:User.customer_list[msg.customer_index].name})}</span>
								<Dialog
									bodyCls="f-column"
									bodyStyle={{fontSize:"14px", fontWeight:"bold", padding:"3px"}}
									style={{position:"fixed", top:"85px"}}
									modal
									header={null}
									resizable={true}
									closable={false}
									footer={() => <div>
										<ButtonGroup style={{ width: '100%', height: '50px' }}>
											<LinkButton style={{marginRight:"3px"}} 
											onClick={() => {
												updatesess({ variables: { customer_index: curCustIndex.current } });
												toast.dismiss("customerchange");
											}}>{t("lbl_custcnguseold", {cname:User.customer_list[curCustIndex.current].name})}</LinkButton>
											<LinkButton onClick={() => {
												updateUserPref({ customer_index: msg.customer_index });
												toast.dismiss("customerchange");
											}}>{t("lbl_custcngusenew", {cname:User.customer_list[msg.customer_index].name})}</LinkButton>
										</ButtonGroup>
									</div>}
								>
									{t("lbl_custcngmsg")}
								</Dialog>
							</div>
							, { closeOnClick: false, closeButton: false, draggable: false, toastId: "customerchange" }); //make the toast only closable by the buttons above
						}
					} else {
						log.trace("unused", "%O", {name:"subscription:customerchange", data: subscriptionData.data.customerchange});
					}
					return prev;
				}
			});
			const logUnsub = subAuth({
				document: gql`
					subscription($token: String) {
						loglevel(token: $token)
					}
				`,
				variables: { token: XToken },
				updateQuery: (prev, { subscriptionData }) => {
					log.setFilter(subscriptionData.data.loglevel);
				}
			});


			return () => {
				custUnsub();
				logUnsub();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subAuth, XToken, curCustIndex, User]);

	useEffect(() => {
		log.onLog = ({ level, namespace, args }) => {
			sendlog({ variables: { logMsg: { level, namespace, json: stringify(args) } } });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [XToken]);

	useEffect(() => {
		//expire cookies in 10 hours or 30 days
		let expires = (parseInt(StayLogin) === 1 ? (30 * 24) : 10) * 60 * 60 * 1000;
		let sessionExpires = new Date(new Date().getTime() + expires)
		if (Token !== Cookies.get("token") || parseInt(StayLogin) !== parseInt(Cookies.get("remember"))) { //resets expire on checkbox change
			log.trace("auth", "update token %s", Token);
			Cookies.set('token', Token, { expires: sessionExpires });
		}
		if (XToken !== Cookies.get("x-token") || parseInt(StayLogin) !== parseInt(Cookies.get("remember"))) { //resets expire on checkbox change
			log.trace("auth", "update x-token %s", XToken);
			Cookies.set('x-token', XToken, { expires: new Date(new Date().getTime() + 30 * 60 * 1000) }); //session token, match sessionlife value on server
		}
		if (parseInt(StayLogin) !== parseInt(Cookies.get("remember"))) {
			log.trace("auth", "update remember %s", StayLogin);
			expires = (30 * 24) * 60 * 60 * 1000; //always 30 days
			sessionExpires = new Date(new Date().getTime() + expires)
			Cookies.set('remember', StayLogin, { expires: sessionExpires });
		}
	}, [Token, StayLogin, XToken]);

	useEffect(() => {
		log.trace("lang", "lang change %s", i18n.language);
		if (i18n.language && i18n.language !== User.lang_pref) {
			const newUser = util.clone(User);
			newUser.lang_pref = i18n.language;
			if(newUser.lang_pref.indexOf("-")>=0){
				newUser.lang_pref = newUser.lang_pref.split("-")[0];
			}
			setUser(newUser);
			setTimeout(() => {
				setSideMenuItems(SideBarMenu({ HasPerm, isAuth, t }));
			}, 50);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language]);

	useEffect(() => {
		setTimeout(() => {
			setSideMenuItems(SideBarMenu({ HasPerm, isAuth, t }));
		}, 100);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuth]);

	useEffect(() => {
		//give UI a bit to settle before selecting
		setTimeout(() => {
			log.trace("lang", "lang change %s", i18n.language);

			let findname = window.location.pathname;
			let title;
			if(findname.startsWith("/quote/")) {
				title = t("prod_" + (findname.split("/").slice(-1)[0])) + " " + t("lbl_quotenum").replace(" #", "");
				findname = "/quotes"; //override quote view to highlight quotes
			} else if(findname.startsWith("/order/")) {
				title = t("prod_" + (findname.split("/").slice(-1)[0])) + " " + t("lbl_order");
				findname = "/orders"; //override quote view to highlight orders
			}
		
			let item = treeHelper.findNode(SideMenuItems, 'path', findname);
			setSideMenuSelected(item);
			if(title) {
				setPageTitle(title);
			} else if(item) {
				setPageTitle(item.text);
			}
		}, 100);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [SideMenuItems]);

	useEffect(() => {
		window.GotoPage = GotoPage;
	});

	function VersionCheck(v) {
		if (v && !util.isdevsystem() && config.version !== v) {
			toast.warn(() => <div>
				<span>{t("lbl_newversion")}</span>
				<LinkButton onClick={() => window.location.reload(true)} >{t("lbl_reload")}</LinkButton>
			</div>
				, { toastId: "vcheck" });
		}
	}
	async function keepalive() {
		if (isAuth) KeepaliveFetch();
		else VersionFetch();
	}

	async function authenticate(user, wilsonauto) {
		let loginfunc = wilsonauto ? doLoginWilson : doLogin;
		return await loginfunc(wilsonauto || user)
			.then(logindata => {
				if (logindata === false || logindata.token === '') {
					setStayLogin(0);
					Cookies.remove("wtok"); //clear it so auto login isn't confused
					setXToken('');
					setUser(util.clone(defaultUser));
					setAuth(false);
					return false;
				} else {
					log.trace("auth", "logindata %O", { ...logindata, userdata: { ...logindata.userdata, customer_list: [], customer: logindata.userdata.customer_list[logindata.userdata.customer_index] } });
					if (logindata.userdata.wilsonlogin) {
						Cookies.set("wtok", wilsonauto, { expires: new Date(new Date().getTime() + (8 * 60 * 60 * 1000)) });
						setStayLogin(1); //fake auto-relogin
						setToken('');
					} else {
						Cookies.remove("wtok"); //clear it so auto login isn't confused
						setToken(logindata.logintoken);
						if(user.remember !== undefined) {
							setStayLogin(user.remember ? 1: 0); //force to numeric
						}
					}
					setXToken(logindata.token);
					//("lang", i18n.language)
					logindata.userdata.lang_pref = i18n.language; //keep the chosen lang pref
					i18n.changeLanguage(logindata.userdata.lang_pref);
					if(logindata.userdata.lang_pref.indexOf("-")>=0){
						logindata.userdata.lang_pref = logindata.userdata.lang_pref.split("-")[0];
					}
							logindata.userdata.isSales = (logindata.userdata.login_source === 0);
					setQuoteProds(logindata.quoteprods);
					setUser(logindata.userdata);
					if(logindata.loglevel) { //override default log level
						log.setFilter(logindata.loglevel);
					}
					if(curCustIndex.current !== logindata.userdata.customer_index) {
						curCustIndex.current = logindata.userdata.customer_index;
						//non-default cust login?  force-push a change in case there are other sessions
						updatesess({ variables: { customer_index: curCustIndex.current, forcecchg: true } });
					}
					setAuth(true);
					logindata.version && VersionCheck(logindata.version);
					log.trace("auth", "doLogin true");
					return logindata.userdata.web_version;
				}
			});
	}

	function signout() {
		log.trace("auth", "signout")
		logout().then(() => {
			Cookies.remove("wtok"); //clear it so auto login isn't confused
			setStayLogin(0);
			setAuth(false);
			setUser(util.clone(defaultUser));
			//setToken('');
			setXToken('');
		});
	}

	function updatePref(pref) {
		if (isAuth) {
			let newpref = {};
			if (pref.lang_pref && pref.lang_pref !== User.lang_pref) {
				newpref.lang_pref = pref.lang_pref;
				i18n.changeLanguage(pref.lang_pref);
			}
			if (pref.units_pref && pref.units_pref !== User.units_pref) newpref.units_pref = pref.units_pref;
			if (pref.customer_index !== undefined && pref.customer_index !== User.customer_index) {
				newpref.customer_index = pref.customer_index;
				curCustIndex.current = parseInt(pref.customer_index); //pre-set to bypass subscription
			}
			if (Object.keys(newpref).length > 0) {
				log.trace("sess", "save user data, %O", newpref);
				updatesess({ variables: newpref })
			}
		} else if (pref.lang_pref && pref.lang_pref !== User.lang_pref) {
			let NewUser = util.clone(User);
			i18n.changeLanguage(pref.lang_pref);
			setUser(NewUser);
			let sesspref = {
				lang_pref: NewUser.lang_pref,
				units_pref: NewUser.units_pref,
				customer_index: parseInt(NewUser.customer_index)
			};
			log.trace("sess", "updateUserPref %O", sesspref);
			Cookies.set('sesspref', JSON.stringify(sesspref));
		}
	}

	const updateUserPref = useCallback((pref) => {
		let NewUser = util.clone(User);
		let skipSave = true;
		log.trace("sess", "new user data, %O", { pref, User: { lang_pref: User.lang_pref, units_pref: User.units_pref, customer_index: User.customer_index } });
		if (pref.lang_pref && pref.lang_pref !== User.lang_pref) {
			NewUser.lang_pref = pref.lang_pref;
			i18n.changeLanguage(NewUser.lang_pref);
			skipSave = false;
		}
		if (pref.units_pref && pref.units_pref !== User.units_pref) {
			NewUser.units_pref = pref.units_pref;
			skipSave = false;
		}
		if (pref.customer_index !== null && pref.customer_index !== User.customer_index) {
			NewUser.customer_index = pref.customer_index;
			skipSave = false;
			FetchProds({variables: { }});
		}
		if (!skipSave) {
			let sesspref = {
				lang_pref: NewUser.lang_pref,
				units_pref: NewUser.units_pref,
				customer_index: parseInt(NewUser.customer_index)
			};
			curCustIndex.current = parseInt(NewUser.customer_index);
			log.trace("sess", "updateUserPref %O", sesspref);

			setUser(NewUser);
			Cookies.set('sesspref', JSON.stringify(sesspref));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [User, i18n]);

	function APIError(error, passtimeout) {
		let errmerged = [];
		let ret = [];
		error.networkError && error.networkError.result && error.networkError.result.errors && errmerged.push(...error.networkError.result.errors);
		error.graphQLErrors && errmerged.push(...error.graphQLErrors);
		errmerged.forEach(e => {
			if(passtimeout && e.message === "sql_error" && e.extensions && e.extensions.originalError && e.extensions.originalError.code === "ETIMEOUT") {
				ret.push("timeout"); //let calling code handle timeout messages
			} else if (e.message === "err_login_failed") {
				//don't show this
				log.error("_", "[APIError] %O", e);
			} else if (User.isSales) {
				let msg = e.message+'...';
				if (msg === "sql_error" && e.extensions && e.extensions.originalError) {
					if(e.extensions.originalError.code){
						toast.error("code: "+e.extensions.originalError.code);
					}
					msg = "sql:"+ (e.extensions.originalError.message || e.extensions.originalError.info.message);
				}
				toast.error(t("err_server_inhouse", { msg: msg }), {
					draggable: false,
					closeOnClick: false
				})
				log.error("_", "[APIError] %O", e);
			} else {
				let msg = t("err_server");
				if (e.message === "sql_error" && e.extensions && e.extensions.originalError && e.extensions.originalError.code === "ETIMEOUT") {
					msg = t("err_databasetimeout");
				}
				toast.error(msg)
			}
		});
		return ret
	}

	function t(phrase, opts) {
		//for %s replacement, opts is an object with var# as keys.  like {var1: "foo", var2: "bar"}
		if (phrase) phrase = phrase.trim();
		return realT(phrase, opts);
	}

	async function CurrencyConvert(value, from, to) {
		let ret = await currency_convert({variables:{inputs:[{
			value: value,
			from_cur: from,
			to_cur: to
		}]}});
		let x = ret.data.currency_convert.shift()
		return x.conv_value;
	}

	function HasPerm(key) {
		let hasperm = false;
		let ua = parseInt(User.website_access);
		switch (key) {
			case 'access':
				//no eula or inactive account will be 0	
				if (ua > 0) hasperm = true;
				break;
			case 'order':
				if (ua & 1) hasperm = true;
				break;
			case 'roquote':
				if (ua & 2) hasperm = true;
				break;
			case 'quote':
				if (ua & 4) hasperm = true;
				break;
			case 'billing':
				if (ua & 8) hasperm = true;
				break;
			case 'taper':
				if (ua & 4) hasperm = true; //allow all quoters access
				break;
			case 'press':
				if (ua & 64) hasperm = true;
				break;
			case 'admin':
				if (ua & 128) hasperm = true;
				break;
			default:
		}
		return hasperm;
	}

	function GotoPage(page, title) {
		//quotes and orders have to be fully reloaded, cleanest way is to nav back to list then to order real fast like
		let findname = page.split("?")[0];
		if(findname.startsWith("/quote/")) {
			title = t("prod_" + (findname.split("/").slice(-1)[0])) + " " + t("lbl_quotenum").replace(" #", "");
			findname = "/quotes"; //override quote view to highlight quotes
			if(window.location.href.indexOf("/quote/") >= 0){
				nav(findname);
			}
		} else if(findname.startsWith("/order/")) {
			title = t("prod_" + (findname.split("/").slice(-1)[0])) + " " + t("lbl_order");
			findname = "/orders"; //override quote view to highlight orders
			if(window.location.href.indexOf("/order/") >= 0){
				nav(findname);
			}
		}
		
		nav(page);

		let item = treeHelper.findNode(SideMenuItems, 'path', findname);
		setSideMenuSelected(item);
		if(title) {
			setPageTitle(title);
		} else if(item) {
			setPageTitle(item.text);
		} else {
			setPageTitle("");
		}
	}

	function ChatToggle() {
		setChatProps({ closed: !chatprops.closed });
	}

	const defaultContext = {
		APIError,
		authenticate, isAuth,
		bodyWidth, setBodyWidth,
		escClose, BindEscClose,
		CurrencyConvert,
		GotoPage,
		keepalive,
		quoteProds,
		pageTitle, setPageTitle,
		signout,
		StayLogin, setStayLogin,
		SideMenuItems, SideMenuSelected,
		t, User, HasPerm,
		Token, XToken,
		updatePref,		
		sendlog,
		ChatToggle, chatprops,
		searchFilters, setSearchFilters
	};

	return (
		<SessionContext.Provider value={defaultContext}>
			{children}
		</SessionContext.Provider>
	);
};
